.tabButton.ant-btn:not([disabled]):hover {
    background-color: #C9C9C9 !important ;
    }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 5px !important;
    }
  .ant-picker {
        border-radius: 5px !important;
        height: 1.8rem !important;
    }
    .tabTable .ant-table-summary {
        overflow: hidden !important;
      }
      .ant-tabs-tabpane {
        border: 0px !important;
      }

      .multiTab .ant-tabs-tab {
        padding: 4px 0 !important;
      }
 .ant-tabs-top > .ant-tabs-nav {
        margin:0 !important;
      }

      .formCollapse.ant-collapse{
    border: 0px solid #b0b0b00d !important;
} 
.ant-modal-content,.ant-modal-header {
  border-radius: 5px !important;
}

.ant-modal-content,.ant-modal-footer {
  border-radius: 5px !important;
}
.tabModal .ant-modal-close-x {
    top: 0 !important;
    right:0px !important;
  }
  @keyframes pulse-bg {
    0% {
      transform: scale(1);
      opacity: 0.6;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0.6;
    }
  }
  